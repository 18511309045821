<template>
  <section class="container pb-8">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-8 position-relative bg-outer">
        <div
          class="bg-image rounded"
          :style="{
            paddingBottom: '50%',
            backgroundImage: 'url(' + BookingData.bookingImgPath + ')',
          }"
        ></div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-between">
        <h5 class="mt-0 mb-1 p-3">{{ BookingData.bookingName }}</h5>
        <div
          class="cs-table d-block justify-self-stretch flex-full p-3 mb-auto bg-light border-shadow"
          data-style="7"
        >
          <dl>
            <dt>剩餘人數：</dt>
            <dd>
              <span class="mr-1">{{ BookingData.bookingLimit - BookingData.bookingCount }}</span
              >人
            </dd>
          </dl>
          <dl>
            <dt>講堂日期：</dt>
            <dd>{{ BookingData.bookingDate | toTaipei | moment('YYYY-MM-DD') }}</dd>
          </dl>
          <dl>
            <dt>報名時間：</dt>
            <dd>
              {{ BookingData.bookingStartDate | toTaipei | moment('YYYY-MM-DD') }} -
              {{ BookingData.bookingEndDate | toTaipei | moment('YYYY-MM-DD') }}
            </dd>
          </dl>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary w-100 py-2 py-md-3"
              @click="SaveBookingSignUp(BookingData.bookingId)"
            >
              我要申請報名
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 mt-md-8">
        <div class="cs-table" data-style="1">
          <div class="border-shadow mb-6">
            <h6 class="table__title bg-info text-dark text-center">講堂介紹</h6>
            <div class="table__body bg-light edit" v-html="BookingData.bookingContent"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetBookingById, SaveBookingSignUp } from '@/api/BookingApi';

export default {
  props: ['currentPage'],
  data() {
    // 建立你的初始化 model data
    return {
      BookingData: {
        bookingContent: '',
        bookingCount: 0,
        bookingDate: '',
        bookingEndDate: '',
        bookingId: 0,
        bookingImgPath: '',
        bookingLimit: 0,
        bookingName: '',
        bookingPlace: '',
        bookingStartDate: '',
      },
    };
  },
  created() {
    this.GetBookingById();
  },
  methods: {
    GetBookingById() {
      const { id } = this.$route.params;
      GetBookingById(id).then((response) => {
        if (response.data.statusCode === 0) {
          this.BookingData = response.data.data;
          this.$emit('update:currentPage', {
            name: '',
            title: this.BookingData.bookingName,
            path: '',
          });
        }
      });
    },
    SaveBookingSignUp() {
      if (this.$UserAccount === '') {
        this.$message({
          showClose: true,
          type: 'error',
          message: '請先登入再進行報名申請。',
        });
        this.$router.push({ path: '/login', query: { redirect: this.$route.path } });
      } else {
        const { id } = this.$route.params;
        SaveBookingSignUp({ BookingId: id }).then((response) => {
          if (response.data.statusCode === 0) {
            this.$message({
              showClose: true,
              type: 'success',
              message:
                '已提交報名申請。※報名申請並非報名成功，請注意查收電子信箱是否有報名成功的通知。',
            });
          }
        });
      }
    },
  },
};
</script>

<style></style>
